/* General styles */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
}

.container-mobile {
    padding: 0;
    margin: 0;
}

.div-wrap {
    position: relative;
}

/* header  */
.bg-grey {
    background-color: #efefef;
    padding-bottom: 2px;
    /* height: 100vh; */
}

.upper-section {
    width: 100%;
    height: 90px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 40px;
    position: fixed;
    z-index: 3;
    max-width: 500px;
}

.challenge-add-upper-section {
    width: 100%;
    height: 50px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    z-index: 3;
    max-width: 500px;
}

.chat-upper-section {
    width: 100%;
    height: 50px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    z-index: 3;
    max-width: 500px;
}

.upper-dark {
    background-color: #3d3d3d;
}

.upper-arrow button {
    border: none;
    background: none;
}

.upper-arrow button img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    background-color: transparent;
}

.upper-photo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid #f95d2d;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center
}

.upper-photo2 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #f95d2d;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center
}

.text1-w {
    font-size: 12px;
    color: white;
}

.text2-w {
    font-size: 14px;
    color: white;
}

.text-orange {
    font-size: 14px;
    color: #f95d2d;
    font-weight: 700;
}

.text2-g {
    font-size: 19px;
    /* color: grey; */
    color: #000;
    font-weight: 600;
    margin-top: -4px;
}

.text2-g2 {
    font-size: 19px;
    /* color: grey; */
    color: #c4cccf;
    font-weight: 600;
    margin-top: -4px;
}

/* Homepage  */


.home-upper-section {
    width: 100%;
    height: 140px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    padding: 20px 40px;
    position: fixed;
    z-index: 3;
    max-width: 500px;
}

.home-upper-section2 {
    width: 100%;
    height: 140px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 20px 20px;
    max-width: 500px;
    position: fixed;
    top: 0;
    z-index: 3;
    background-color: white;
    transition: top 0.3s ease-in-out;
}

.home-upper-about {
    width: 250px;
}

.home-upper-about-in {

    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 37px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1.2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.home-upper-section2.hide {
    top: -140px;
}

.home-upper-section2.show {
    top: 0;
}

.filter-wrap .dropdown-item:active {
    background-color: #9ea2a7;
}

.dropdown-level .dropdown-menu.show {
    right: 0 !important;
    margin-bottom: 3px !important;
    padding-bottom: 0px !important;
    color: #3d3d3d !important;
    border-color: white !important;
    border-radius: 8px !important;
}

.dropdown-level .dropdown-menu.show {
    min-width: max-content !important;
}

.dropdown-level .dropdown-menu.show li {
    border-bottom: 3px solid #e5e5e5 !important;
}

.dropdown-level .dropdown-menu.show li:last-child {
    border-bottom: none !important;
}

.challenge-add-upper-section.hide {
    top: -50px;
}

.challenge-add-upper-section.show {
    top: 0;
}

.home-post {
    margin-top: 10px;
}

.home-post form {
    display: flex;
}

.octagon {
    display: flex;
    align-items: baseline;
    margin-top: -50px;
}

.octagon-button {
    border: none;
    background: none;
    width: min-content;
}

.home-post-box {
    width: 100%;
    display: flex;
    position: relative;
    border: 1px solid #f95d2d;
    line-height: 10px;
    padding: 5px 0px;
    height: 35px;
    border-radius: 5px;
    justify-content: space-between;
}

.home-post-box-buttons {
    gap: 10px;
    display: flex;
    padding-right: 2px;
}

.home-post-box-buttons button {
    border-radius: 50%;
    background: none;
    width: 25px;
    height: 25px;

}

.home-post-box textarea {
    width: 75%;
    border-radius: 5px;
    padding: 3px 10px;
    font-size: 14px;
    border: none;
    outline: none;
    resize: none;
}

.home-posts-item {
    background-color: white;
    border-radius: 20px;
    margin: 20px;
}

.home-post-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    border-radius: 20px;
}

.home2-posts-item {
    margin-bottom: 20px;
}

.home-avatar-item {
    background-color: white;
    padding: 10px;
}

.home2-post-image {
    width: 100%;
    overflow: hidden;
    background-color: #000;
}

.home2-post-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Ensuring the footer section has a white background */
.d-flex.justify-content-between.px-3 {
    background-color: white;
}

.dynamic-image {
    width: 100%;
    height: auto;
}


.wide {
    object-fit: cover;
}

.narrow {
    object-fit: contain;
}

.home2-post-image img.wide {
    object-fit: cover;
}

.home2-post-image img.narrow {
    object-fit: contain;
    width: 100%;
    height: auto;
}

.home-upper-section2.bg-white,
footer {
    transition: transform 0.3s ease-in-out;
}

.challenge-add-upper-section {
    transition: transform 0.3s ease-in-out;
}

.carousel-inner {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.carousel-item {
    flex: 0 0 auto;
    width: 110px;
    /* Ensures each item is at least 110px wide */
    margin-right: 10px;
}

.carousel-item img {
    width: 100%;
    height: 196px;
    /* Ensures each item is at least 196px tall */
    object-fit: cover;
}

.carousel-item a {
    display: block;
}

.home-post-video-wrapper {
    height: 300px;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-radius: 20px; */
}

.home-post-video-wrapper video {
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
}


.home-post-points {
    display: flex;
    gap: 30px;
    color: black;
    font-size: 16px;
    padding: 10px 30px;
    border-bottom-left-radius: 31px;
    border-bottom-right-radius: 31px;
    width: 100%;
}

.home2-post-points {
    display: flex;
    color: black;
    font-size: 16px;
    align-items: center;
    padding-right: 10px;
}

.home-post-points img {
    width: 20px;
    padding-right: 5px;
}

.home2-post-points img {
    width: 20px;
    padding-right: 5px;
}

.home-post-points-picker {
    display: flex;
    gap: 10px;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0px;

}

.home-header {
    font-size: 11px;
    color: #f95d2d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 7px 15px;
}

.home-header-text {
    font-weight: bold;
    font-size: 14px;
}

.home-avatar-item {
    display: flex;
    padding: 20px;
    padding-bottom: 0px;
    align-items: center;
}

.home-user-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
}

.home2-user-photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
}

.home2-user-line {
    border-bottom: 1px solid #d7d7d77a;
}

.home-practice-days {
    padding: 8px 20px;
    background-color: white;
    font-size: 14px;
    color: #000;
}

.rounded {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 90px;
    height: 135px;
    position: relative;
    margin: 5px 7px;
}

.overlay-text {
    font-size: 12px;
    font-weight: bold;
    top: unset;
    color: #f95d2d;
    padding: 3px;
    background-color: #000000a1;
}

.home-challenge-accept {
    display: flex;
    align-items: center;
    margin-left: auto;
    flex-grow: 3;
    justify-content: end;
}

.home-challenge-accept a {
    text-decoration: none;
}

.home-challenge-accept button {
    border-radius: 50%;
    font-size: 11px;
    width: 50px;
    height: 50px;
    position: relative;
    background: #f95d2d;
    color: white;
    border: 1px solid white;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 12px;
    box-shadow: 2px 5px 5px #b54d2c;
}

.home-challenge-accept button:hover {
    background: #b54d2c;
    box-shadow: 2px 5px 5px #f95d2d;
}


.home-challenge-accept button img {

    position: absolute;
    top: -5px;
    left: -5px;
    border: 1px solid #f95d2d;
    border-radius: 50%;
    height: 20px;
    padding: 1px;
    background-color: white;
}

.home-challenge-accept-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 12px;
    margin-top: 17px;
}



@media (min-width: 1200px) {
    .gap-xl-screen {
        gap: 15px;
    }

    .carousel-inner .carousel-item>div:first-child {
        display: block
    }
}

@media (max-width: 267px) {
    .carousel-inner .carousel-item>div {
        display: none
    }

    .carousel-inner .carousel-item>div:first-child {
        display: block
    }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
    display: flex;
    /* justify-content: center; */
}

@media (min-width: 268px) {

    .carousel-inner .carousel-item-end.active,
    .carousel-inner .carousel-item-next {
        transform: translateX(25%)
    }

    .carousel-inner .carousel-item-start.active,
    .carousel-inner .carousel-item-prev {
        transform: translateX(-25%)
    }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
    transform: translateX(0)
}

.carousel-indicators span {
    background-color: #000 !important;
    /*more custom style*/
}

.carousel-indicators .active {
    background-color: grey !important;
}


/* chat */
.chat-header {
    font-size: 11px;
    /* color: grey; */
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 15px;
}

.chat-header-text {
    font-weight: bold;
}

.chat-avatars-wrapper {
    padding-bottom: 50px;
}

.chat-avatar-item {
    display: flex;
    padding: 12px;
    background-color: white;
    margin: 20px 10px;
    align-items: center;
}

.chat-user-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-header-time {
    color: #f95d2d;
}

.chat-read {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #efefef;
}

.chat-unread {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #f95d2d;
}

.section-margin {
    margin-bottom: 100px;
    padding-top: 100px
}

.chat-between-item {
    display: flex;
    padding: 20px;
    justify-content: space-around;
    align-items: center;
}



.chat-bubble-user {
    position: relative;
    background: #f95d2d;
    color: #FFFFFF;
    font-family: Arial;
    font-size: 11px;
    text-align: center;
    margin-left: 20px;
    padding: 15px;
    width: 250px;
}

.chat-bubble-other {
    position: relative;
    background: #dfdfdf;
    color: black;
    font-family: Arial;
    font-size: 11px;
    text-align: center;
    margin-right: 20px;
    padding: 15px;
    width: 250px;
}

.chat-bubble-user:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: transparent #f95d2d;
    border-width: 10px 10px 10px 0;
    top: 58%;
    left: -9px;
    margin-top: -20px;
}

.chat-bubble-other:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: transparent #dfdfdf;
    border-width: 10px 0 10px 10px;
    top: 58%;
    right: -9px;
    margin-top: -20px;
}

.chat-time {
    color: #f95d2d;
    font-size: 14px;
    text-align: center;
    padding: 5px 0px;
    font-weight: 700;
}

.chat-say {
    padding: 20px;
    display: flex;
    position: relative;
    position: fixed;
    bottom: 0;
    max-width: 500px;
    width: 100%;
    background-color: #FFFFFF;
    z-index: 2;
}

.chat-input {
    width: 100%;
    padding: 15px;
    border: 1px solid #f95d2d;
}

.arrow-submit {
    position: absolute;
    right: 10%;
    background-color: transparent;
    top: 35%;
    border: none;
}

/* Challenges  */
.challenge-time {
    color: #f95d2d;
    font-size: 18px;
}

.challenge-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 260px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    position: relative;
}

.no-margin {
    margin-bottom: -35px;
    z-index: 2;
    position: relative;
}

.need-help {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3d3d3d;
    color: #FFFFFF;
    padding: 5px 10px;
    font-size: 12px;
}

.active-members {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3d3d3d;
    color: #dfdfdf;
    padding: 7px 12px;
    font-size: 12px;
}

.help-buttons {
    display: flex;
    padding-left: 10px;
}

.help-buttons button {
    background: none;
    border: none;
}

.help-buttons button img {
    width: 20px;
    height: 20px;
    padding: auto;
}

.challenge-posts-item {
    padding: 0px 20px;
}

.challenge-post-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px;
    border-radius: 30px;
    position: relative;
    margin: 25px 0px;
}

.challenge-post-points {
    display: flex;
    background-color: #0e0e0eba;
    gap: 15px;
    color: white;
    font-size: 11px;
    padding: 10px 15px;
    border-bottom-left-radius: 31px;
    border-bottom-right-radius: 31px;
    position: absolute;
    width: 100%;
    bottom: 0;
    justify-content: space-between;
    align-items: center;
}

.challenge-post-title {
    max-width: 180px;
}

.challenge-post-points button {
    background: none;
    border: none;
}

.challenge-post-points img {
    width: 20px;
    padding-right: 5px;
}

/* add  challenge or Post*/
.challenge-add-post-wrapper {
    position: absolute;
    bottom: 80px;
    right: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.add-post-text {
    background-color: white;
    padding: 10px 20px;
}

.add-post-media {
    position: relative;
}

.add-post-media img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.form-control2 {
    outline: none;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    width: 100%;
    padding-top: 40px;
    height: 100%;
    box-sizing: border-box;
}


.paperclip {
    color: white;
    background-color: #3d3d3d;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: none;
}

.pin-orange {
    background-color: #f95d2d;
    border: 1px solid #f95d2d;
    color: white;
}


.pin-blue {
    background-color: #2b73fb;
    border: 1px solid #2b73fb;
    color: white;
}

.pin-white {
    background-color: white;
    border: 1px solid #2b73fb;
    color: #2b73fb;
}

.pin-grey {
    background-color: #3d3d3d;
    border: 1px solid #3d3d3d;
    color: white;
}

.pin-grey:hover {
    background-color: white;
    border: 1px solid #3d3d3d;
    color: #3d3d3d;
}


.challenge-add-choose>input {
    display: none;
}

/* .image-upload>input {
    display: none;
} */

.challenge-add-choose {
    position: fixed;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 0px 20px;
    gap: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: -10px;
    height: 70px;
    cursor: pointer;
    bottom: 0;
    width: 100%;
}

.challenge-add-choose img {
    width: 35px;
    height: 35px;
}



/* Challenge single  */
.challenge-single-post-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 700px;
    border-radius: 30px;
    position: relative;
    margin: 25px 0px;
}


/* Challenges Multi  */
.challenge-multi-post-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 250px;
    border-radius: 30px;
    position: relative;
    margin: 25px 0px;
}

.modal-specs {
    margin: 0px 40px;
    border: 1px solid #f95d2d;
}

.modal-text {
    font-weight: 600;
    font-size: 12px;
    padding-top: 5px;
}

.modal-footer {

    display: flex;
    padding: 0;
    font-size: 12px;

}

.modal-footer button {
    color: black;
    background: none;
    border: none;
    border-top: 1px solid #f95d2d;
    padding: 12px;
}

.modal-footer button:hover {
    color: #f95d2d;
}

/* Challenge selected  */

.challenge-selected-all-posts {
    display: flex;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px 20px;
    justify-content: space-between;
}



.challenge-selected-user-photo {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #f95d2d;
}

.challenge-selected-user-photo2 {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    display: flex;
    border: 1px solid #f95d2d;
    position: absolute;
    top: 10px;
}

.challenge-selected-post-box {
    display: flex;
    position: relative;
    padding: 10px 20px;
    border-radius: 20px;
    background-color: white;
    margin: 10px 20px;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}


.challenge-selected-post-box-buttons {
    gap: 10px;
    display: flex;
    padding-right: 2px;
}

.challenge-selected-box-buttons button {
    border-radius: 50%;
    background: none;
    width: 25px;
    height: 25px;

}

.challenge-selected-post {
    display: flex;
    border: #000;
    position: relative;
    /* width: 380px; */
}

.challenge-selected-box {
    display: flex;
    width: 100%;
    border: 1px solid black;
    position: relative;
    padding: 5px 15px;
    border-radius: 5px;
    justify-content: space-between;
}

.challenge-selected-box input {
    border: none;
    font-size: 12px;
    outline: none;
}

.challenge-selected-post-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 350px;
    border-radius: 30px;
    position: relative;
    margin: 5px 0px;
    width: 210px;
}

.height400 {
    height: 400px;
}


.challenge-press-points {
    display: flex;
    gap: 20px;
    color: white;
    font-size: 14px;
    position: absolute;
    top: 40px;
    right: 10px;
    flex-direction: column;
}

.challenge-press-points-full {
    display: flex;
    gap: 20px;
    color: white;
    position: absolute;
    bottom: 70px;
    right: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 11px;
}

.challenge-name-info {
    position: absolute;
    bottom: 80px;
    left: 20px;
    right: 75px;
    word-break: break-word;
}

.challenge-press-button {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background-color: #0000001a;
    border: none;
}

.challenge-press-button.active {

    background-color: darkorange;

}

.challenge-press-button img {
    width: 31px;
    align-items: center;
    display: flex;
    justify-content: center;
}

@keyframes breath {
    0% {
        background-size: 60% auto;
    }

    50% {
        background-size: 20% auto;
    }

    100% {
        background-size: 60% auto;
    }
}

.zoom-points {
    animation: zoom-point 1s ease infinite;
}

.zoom-anim {
    animation: zoom-in-zoom-out 1.4s ease infinite;
}

@keyframes zoom-point {
    0% {
        transform: scale(0.2, 0.2);
    }

    50% {
        transform: scale(1, 1);
    }

    100% {
        transform: scale(0.2, 0.2);
    }
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(2, 2);
    }

    100% {
        transform: scale(1, 1);
    }
}


.full-animation {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%);
    margin: 10px;
    z-index: 10;
}

.full-animation.hide {
    display: none;
}


.full-animation3 {
    position: absolute;

    transform: translate(-50%, -50%);
    margin: 10px;
    z-index: 10;
}

.full-animation2.hide {
    display: none;
}

.points-animation {
    position: absolute;
    z-index: 10;
    right: 50px;
}

/* .points-animation {
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */

.points-animation.hide {
    display: none;
}


/* #award-full{
    width: 80%;
    height: 80%;
    animation: breath 2s linear infinite;
    background: url("images/awardfull.png") center center no-repeat;
  } */
/* .fire {
    content: url("images/fire2-stop.png");
    transform: translateX(-15px) translateY(-10px) scale(0.6);
    -webkit-transform: translateX(-15px) translateY(-10px) scale(0.6);
    -moz-transform: translateX(0) translateY(0) scale(1.3);
    -ms-transform: translateX(0) translateY(0) scale(1.3);
    -o-transform: translateX(0) translateY(0) scale(1.3);
}

.fire-animated {
    content: url("images/fire2.gif");
    transform: translateX(-15px) translateY(-10px) scale(0.6);
    -webkit-transform: translateX(-15px) translateY(-10px) scale(0.6);
    -moz-transform: translateX(0) translateY(0) scale(1.3);
    -ms-transform: translateX(0) translateY(0) scale(1.3);
    -o-transform: translateX(0) translateY(0) scale(1.3);
}

.thunder {
    content: url("images/thunder3-stop.png");
    transform: translateX(-15px) translateY(-10px) scale(0.5);
    -webkit-transform: translateX(-15px) translateY(-10px) scale(0.5);
    -moz-transform: translateX(0) translateY(0) scale(1.1);
    -ms-transform: translateX(0) translateY(0) scale(1.1);
    -o-transform: translateX(0) translateY(0) scale(1.1);
}

.thunder-animated {
    content: url("images/thunder4.gif");
    transform: translateX(-15px) translateY(-10px) scale(0.5);
    -webkit-transform: translateX(-15px) translateY(-10px) scale(0.5);
    -moz-transform: translateX(0) translateY(0) scale(1.1);
    -ms-transform: translateX(0) translateY(0) scale(1.1);
    -o-transform: translateX(0) translateY(0) scale(1.1);
}

.achievement {
    content: url("images/achievement2-stop.png");
    transform: translateX(-15px) translateY(-10px) scale(0.5);
    -webkit-transform: translateX(-15px) translateY(-10px) scale(0.5);
    -moz-transform: translateX(0) translateY(0) scale(1.1);
    -ms-transform: translateX(0) translateY(0) scale(1.1);
    -o-transform: translateX(0) translateY(0) scale(1.1);
}

.achievement-animated {
    content: url("images/achievement2.gif");
    transform: translateX(-15px) translateY(-10px) scale(0.5);
    -webkit-transform: translateX(-15px) translateY(-10px) scale(0.5);
    -moz-transform: translateX(0) translateY(0) scale(1.1);
    -ms-transform: translateX(0) translateY(0) scale(1.1);
    -o-transform: translateX(0) translateY(0) scale(1.1);
} */

/* challenge selected 2  */
.rotated {
    transform: rotate(90deg);
    /* Adjust transform origin to ensure correct positioning */
    transform-origin: center center;
    /* To make sure it fits in the container properly */
    width: auto;
    height: 100%;
}


.chal-video-wrapper {
    width: 100%;
    height: 100%;
    position: relative
}

.chal-video-wrapper video {

    width: 100%;
    height: auto;
    object-fit: contain;
}

.chal-left {
    position: absolute;
    z-index: 2;
    bottom: 100px;
    right: 70px;
    left: 15px;
}

.chal-right {
    position: absolute;
    z-index: 2;
    right: 10px;
    bottom: 120px;

}

.chal-press-points-full {
    display: flex;
    gap: 20px;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 11px;
}




/* Settings  */
.settings-item {
    display: flex;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin: 20px;
}

.setting-title {
    display: flex;
    font-size: 15px;
    font-weight: bold;
    gap: 20px;
    align-items: center;
}

.setting-value {
    font-size: 12px;
}




/* wall of fame  */
.bg-orange {
    background: #f95d2d;
}


.wof-avatar-item {
    border-radius: 20px;
    margin: 20px;
}

.wof-wrapper {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    border-radius: 20px;
    margin: 0px 20px;
}

.wof-user {
    display: flex;
}

.wof-header {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 15px;
}

.wof-header-name {
    font-weight: bold;
    font-size: 16px;
}

.wof-header-team {
    font-size: 11px;
}

.wof-user-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
}

.wof-user-photo button {
    background: none;
    border: none;
}

.wof-user-photo button:focus-visible {
    background: none;
    border: none;
    outline: none;
}


.slide-out {
    animation: slideOut 0.8s forwards;
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
        opacity: 0;
    }
}

.hidden-div {
    display: none;
}





.wof-points {
    background-color: white;
    font-size: 11px;
    font-weight: bold;
    padding: 6px 40px;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;

    box-shadow: 0px 5px 5px 0px rgba(206, 197, 197, 0.75);
    -webkit-box-shadow: 0px 5px 5px 0px rgba(206, 197, 197, 0.75);
    -moz-box-shadow: 0px 5px 5px 0px rgba(206, 197, 197, 0.75);
}

.wof-points img {
    width: 20px;
}

/* HOME Post  */

.post-wrapper {
    margin: 0px 20px;
    padding-bottom: 50px;
    /* padding-top: 110px; */

}

.post-text {
    border: 1px solid #f95d2d;
    padding: 10px 20px 20px 20px;
    background: #FFFFFF;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    resize: none;
}

.post-text::placeholder {
    color: #f95d2d;
}

.post-edit-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 2;

}

.post-edit-buttons button {
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #93c3fa;
}

.post-edit-buttons button .bi {

    font-size: 20px;
}

.post-video {
    height: 500px;
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    background-color: #000;
}

.post-video video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: contain;
}

.post-submit {
    background-color: #f95d2d;
    color: white;
    width: 300px;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    margin-top: -20px;
    position: relative;
}

.post-cancel {
    background-color: #d98d95;
    color: white;
    width: 300px;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    margin-top: -20px;
    position: relative;
}

/* Profile  */
.sliders-icon {
    font-size: 30px;
    color: white;
}

.profile3-upper-section {
    width: 100%;
    height: 50px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    position: fixed;
    z-index: 3;
    max-width: 500px;
}

.profile-posts-item {
    margin-top: -35px;
}

.profile-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 330px;
    position: relative;
    overflow: hidden;
}

.profile-user-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 250px;
    border: 1px solid #f95d2d;
    width: 150px;
    margin-left: 20px;
    margin-top: 50px;
    position: relative;

}


.profile-add-button {

    position: absolute;
    bottom: 50px;
    right: 10px
}

.profile-add-button button {
    border: none;
    background-color: #f95d2d;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: relative;
    z-index: 2;
}

.profile-user-stats-wrapper {
    margin-top: -20px;
    position: relative;
    z-index: 2;
    margin-right: 20px;
    margin-left: 20px;
}

.profile-user-stats {
    color: white;
    font-size: 15px;
    font-weight: bold;
    background-color: #3d3d3d;
    width: 100%;

}

.profile-user-stats-text {
    width: 100%;
    text-align: center;
    text-wrap: wrap;
    padding: 15px;
}

.profile-post-followers {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 90px;
    height: 90px;
    text-align: center;
    padding: 20px;
    color: white;
    font-size: 11px;
}

.profile-modify-user {
    gap: 10px;
    position: absolute;
    bottom: 0;
    left: 10px;
    z-index: 2;
}

.profile-add-user button {
    background: white;
    border: none;
    padding: 6px;
}

.profile-delete-user button {
    background: white;
    border: none;
    padding: 6px;
}

.profile-user-info {
    font-weight: bold;
    padding-top: 50px;
    line-height: 25px;
    padding-left: 20px;
}

.profile-user-info-lastname {
    font-weight: bold;
    font-size: 1.7rem;
    color: white;
}

.profile-user-info-name {
    font-weight: bold;
    font-size: 1.2rem;
    color: white;
}

.triangle {
    background-image: url(images/triangle.png);
    height: 50px;
    background-size: cover;
    width: 100%;
    background-color: transparent;
    background-repeat: no-repeat;
    position: relative;

    margin-top: -50px;
}


.profile-user-details {
    font-size: 11px;
    font-weight: 300;
    padding-top: 10px;
    color: white;
}

.profile-followers {
    display: flex;
    position: absolute;
    bottom: 70px;
    right: 80px;
}

.profile-team {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ccc9c95c;
    border-bottom: 1px solid #ccc9c95c;
    margin: 0px 20px;

}

.profile-current-team {
    display: flex;
    flex-direction: column;
    line-height: 18px;
    border-right: 1px solid #ccc9c95c;
    width: 100%;
}

.profile-nationality img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.profile-rank {
    text-shadow: 0 0 1px #f95d2d, 0 0 1px #f95d2d, 0 0 1px #f95d2d, 0 0 1px #f95d2d;
    color: white;
    font-size: 20px;
    font-weight: bold;
}

.profile-previous-teams {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 20px;
}

.profile-bio {
    padding: 0px 20px 10px 0px;
    font-size: 12px;
    border-bottom: 1px solid #ccc9c95c;
    color: #dfdfdf;
    margin: 5px 20px;
}

hr {
    margin-left: 20px;
    margin-right: 20px;
}

.profile-challenges {
    border-bottom: 1px solid #ccc9c95c;
    margin: 20px;
    margin-bottom: 10px;
}

.profile-challenges .row>* {
    padding-left: 0;
}


.profile-challenges-item {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 80px;
    height: 120px;
    border-radius: 5px;
    margin: 2px 10px 2px 0px;
    position: relative;
}

.profile-challenges-button button {
    background: none;
    border: none;
    position: absolute;
    bottom: 5px;
    right: 0;
}

.profile-posts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 20px;
}

.profile-user-posts {
    border-radius: 5px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 128px;
    height: 75px;
    margin: 4px;
}

@media (max-width:450px) {
    .profile-user-info-name {
        font-size: 0.8rem;
    }

    .profile-user-info-lastname {
        font-size: 1.3rem;
    }

    .profile-user-posts {
        width: 121px;
        height: 70px;
        margin: 3px;
    }

}

@media (max-width:400px) {
    .profile-user-posts {
        width: 110px;
        height: 55px;
    }
}

@media (max-width:390px) {
    .profile-user-info-name {
        font-size: 0.6rem;
    }

    .profile-user-info-lastname {
        font-size: 1rem;
    }
}

@media (max-width:370px) {
    .profile-user-posts {
        width: 100px;
        height: 50px;
    }
}



/* Profile ver 3 */
.profile3-bg {
    background-color: #000;
    padding-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
    gap: 15px;
    padding-bottom: 10px;
}

.profile3-user {
    border: 1px solid #f95d2d;
}

.profile3-user-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 200px;
    width: 160px;
    position: relative;
}

.profile3-stats {
    color: white;
    justify-content: space-evenly;
    border-top: 1px solid #f95d2d;
    padding: 10px;
    text-align: center;
}

.profile3-posts-stats {
    border-right: 1px solid gray;
    padding-right: 15px;
}

.profile3-user-info-name {
    font-weight: bold;
    font-size: 14px;
    color: white;
}

.profile3-user-info-lastname {
    font-weight: bold;
    font-size: 12px;
    color: white;
}



.profile3-user-details {
    font-size: 11px;
    font-weight: 300;
    padding-top: 10px;
    color: white;
}

.profile3-info-buttons {
    background-color: #f95d2d;
    color: white;
    display: flex;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 2px 5px;

}

.profile3-bio {
    background-color: #000;
    border-top: 1px solid #3d3d3d;
    padding: 10px 20px;
    font-size: 11px;
    color: white;
}

.profile-locked {
    display: flex;
    flex-direction: column;
    height: 350px;
    padding-top: 60px;
}

.profile-locked-title {
    padding: 10px 30px;
    font-weight: bold;
}

.profile-locked-text {
    padding: 10px 30px;
    font-weight: bold;
}

.locked-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #f95d2d;
    display: flex;
    align-items: center;
    justify-content: center;
}






/* Profile posts  */
.profile-post-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    position: relative;
    margin: 25px 0px;
}

.profile-post-points {
    display: flex;
    background-color: #0e0e0eba;
    gap: 15px;
    color: white;
    font-size: 14px;
    padding: 10px 30px;
    border-bottom-left-radius: 31px;
    border-bottom-right-radius: 31px;
    position: absolute;
    width: 100%;
    bottom: 0;
}

.profile-post-points img {
    width: 20px;
    padding-right: 5px;
}



.section-div {
    background-color: #efefef;
}

.video-div {
    text-align: center;
}

.video-div video {
    border-radius: 20px;
    margin: 30px 0px;
}


.rounded-video {
    /* min-height: 700px; */
    height: 600px;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}


.rounded-video .challenge-post-video {
    display: none;
}

.rounded-video .challenge-post-video.show {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
}

.rounded-video .challenge-post-photo {
    display: none;
}

.rounded-video .challenge-post-photo.show {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: #000;
}

.square-video {
    height: 700px;
    overflow: hidden;
    position: relative;
}

.square-video .challenge-post-video {
    display: none;
}

.square-video .challenge-post-video.show {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
}

.square-video .challenge-post-photo {
    display: none;
}

.square-video .challenge-post-photo.show {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* object-fit: contain; */
    background-color: #000;
}

.box-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 15%;
    border-bottom-right-radius: 15%;
    margin-bottom: -100px;
}

.logo img {
    width: 100px;
}

.form-div {
    padding: 40px;
    margin: 0px 30px;
    border-radius: 40px;
    background-color: white;
    margin-bottom: 40px;
    -webkit-box-shadow: 1px 6px 5px 2px rgba(219, 219, 219, 1);
    -moz-box-shadow: 1px 6px 5px 2px rgba(219, 219, 219, 1);
    box-shadow: 1px 6px 5px 2px rgba(219, 219, 219, 1);
}

.orange {
    color: white;
    background-color: #f95d2d;
    width: 100%;
    border: 1px solid #f95d2d;
    padding: 10px;
}

.orange:hover {
    color: white;
    background-color: #b54d2c;
}

.form-buttons {
    border-radius: 5px;
    display: flex;

}

.form-control {
    outline: none;
    border: none;
    border-bottom: 1px solid #dfdfdf;
}

.form-control-date {
    border: 1px solid gainsboro;
}

.date-label {
    font-size: 12px;
    padding-left: 5px;
}

.form-select {
    outline: none;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    /* color: #8d8d8d; */
    color: #000;
    padding-top: 14px;
    padding-bottom: 14px;
}

.form-floating>label {
    /* color: #8d8d8d; */
    color: #000;
}

.form-control:focus-visible {
    box-shadow: 0 0 0 0.25rem #f95d2d3b;
    width: 100%;
    border: none !important;
}

.form-control:focus-within {
    box-shadow: 0 0 0 0.25rem #f95d2d3b;
    width: 100%;
    border: none !important;
}

.form-control:focus {
    box-shadow: 0 0 0 0.25rem #f95d2d3b;
    width: 100%;
    border: none !important;
}

.form-select:focus {
    box-shadow: 0 0 0 0.25rem #f95d2d3b;
    width: 100%;
    border: none !important;
}




.border-grey {
    border: 1px solid #dfdfdf;
    background-color: #efefef;
}


.btn-orange {
    background-color: #f95d2d;
    color: white;
    padding: 10px;
}

.btn-orange:hover {
    color: white;
    background-color: #b54d2c;
}

.btn-grey {
    color: #f95d2d;
    border: none;
    background-color: #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-grey a {
    text-decoration: none;
    color: #f95d2d;
    width: 100%;
}

.password-eye {
    background-color: transparent;
    border: none;
}

.password-box {
    border-bottom: 1px solid #dfdfdf;
}


.forgot-password-link {
    text-align: end;
    font-size: 10px;
    margin-top: 5px;
    margin-bottom: 30px;
}

.forgot-password-link a {
    text-decoration: none;
    color: black;
}

.bi {
    display: flex;
    align-items: center;
    justify-content: center;
}

.greet {
    display: block;
    font-size: 20px;
    /* color: #8d8d8d; */
    color: #000;
    font-weight: 700;
}

.reset-span {
    display: block;
    font-size: 11px;
    /* color: #8d8d8d; */
    color: #000;
    font-weight: 700;
}

.agree-terms {
    font-size: 12px;
    text-align: center;
    margin-bottom: 140px;
    /* color: #8d8d8d; */
    color: #000;
    padding: 0px 10px;
}

.sport-div {
    border-bottom: 1px solid #dfdfdf;
}

.sport-text {
    width: 50%;
    display: flex;
    align-items: center;
    /* color: #8d8d8d; */
    color: #000;
    padding-left: 12px;
}

.sport-select {
    outline: none;

    color: #f95d2d;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #efefef;
}


/* footer */
footer {
    position: fixed;
    bottom: 0;
    max-width: 500px;
    width: 100%;
    z-index: 2;
}

.footer-div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 30px 0px;
    height: 50px;
    border: 1px solid #dfdfdf;
    background-color: white;
}

.footer-item {
    width: 100%;
    text-align: center;
    position: relative;
}

.filter-w {
    filter: brightness(0) invert(1);
}

.footer-bubble {
    position: absolute;
    top: -5px;
    right: 10px;
    border-radius: 50%;
    background-color: #f95d2d;
    color: white !important;
    font-size: 8px;
    padding: 4px;
    width: 20px;
}



.footer-item:hover {
    filter: invert(56%) sepia(87%) saturate(4257%) hue-rotate(344deg) brightness(101%) contrast(95%);
}

.footer-item:active {
    filter: invert(56%) sepia(87%) saturate(4257%) hue-rotate(344deg) brightness(101%) contrast(95%);
}


.footer-item button {
    width: 26px;
    height: 26px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    background-color: transparent;
    padding-bottom: 0px;
}



.footer-item-title {
    text-align: center;
    font-size: 9px;
    margin-top: -1px;
}


@media (max-width:460px) {
    .challenge-selected-post-image {
        height: 250px;
        width: 180px;
    }
}

@media (max-width:400px) {
    .challenge-selected-post-image {
        height: 230px;
        width: 150px;
    }
}

/* @media (max-width:370px) {
    .challenge-selected-post {
        width: 250px;
    }
} */

@media (max-width:330px) {
    .form-buttons {
        display: block;
    }

    .border-grey {
        border: none;
        background-color: white;
    }

    .btn-orange,
    .btn-grey {
        margin-top: 8px;
        border: 1px solid #dfdfdf;
    }

}